<template>
    <section>
        <div class="columns">
            <div class="column">
                <b-notification type="is-warning" has-icon :closable="false">
                    You do not have permissions for the requested page.
                </b-notification>
            </div>
        </div>    
    </section>
</template>
<script>
export default {
    name: 'Forbidden'
}
</script>